<template>
  <div class="ReportBox">
    <TitleHeader msg="我的发票"></TitleHeader>
     <p class="progressBar"></p>
    
     <el-row class="header">
       <el-col :span="2">店铺：</el-col>
        <el-col :span="6">
          <el-select v-model="ShopVal" placeholder="请选择" @change="ShopSearch">
            <el-option
              v-for="item in Shoplist"
              :key="item.id"
              :label="item.enterpriseName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="6">
              <el-date-picker
            v-model="TimeValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            >
          </el-date-picker>
        </el-col>
        <button  class="downBtn" @click="DownAttachFun()">
          <strong class="el-icon-download">发票下载</strong>
        </button>
     </el-row>

     <el-row>
    <ul class="ulBox">
        <li>
          <el-checkbox
              class="checkAll"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
        </li>
        <li>商品</li>
        <li>订单详情</li>
        <li>数量</li>
        <li>交易金额</li>
        <li>交易状态</li>
        <li>操作</li>
      </ul>
    </el-row>
     <el-row class="Detailitem"  
        v-for="(item, index) in tableData"
        :key="index">

        <el-col :span="24" class="headers">
          <div class="checkItemBox">
            <el-checkbox
              true-label="true"
              false-label="false"
              v-model="item.flag"
              @change="handleCheckedChange(item)"
            ></el-checkbox>


          </div>
          <el-col :span="12">
            <el-col :span="8">{{ item.createTime }}</el-col>
            <el-col :span="16">订单ID：{{ item.id }} </el-col>
          </el-col>
          <!-- <el-col :span="12">订单编号: {{ item.sn }}</el-col> -->
        </el-col>

        <table class="main">
        <!-- <thead>
          <th>订单详情</th>
          <th>数量</th>
          <th>金额</th>
          <th>订单状态</th>
          <th>操作</th>
        </thead> -->
          <tbody>
              <template>
                <tr v-for="(items, i) in item.orderDetails" :key="i">
                  <td v-if="i < 2" class="tableBox" :width="400">
                    <div class="imgBox" @click="jumpGoodsDetail(items)">
                      <img :src="items.goodsPic.split(',')[0] | defaultImage" alt="" />
                    </div>
                    <div class="desBox">
                      <p class="desTit">
                        <span :title="items.goodsName">
                          {{ items.goodsName }}
                        </span>
                        <span>x{{ items.goodsCount }}</span>
                      </p>
                      <p class="desMsg">
                        <span v-if="items.specification"
                          >药品规格：{{ items.specification }}</span
                        >
                      </p>
                      <p class="desMsg">
                        <span v-if="items.partSpecification">件装规格：{{ items.partSpecification }}</span>
                      </p>
                    </div>
                  </td>
                  <td
                    v-if="i == 3"
                    class="tableBox"
                    :width="400"
                    @click="jumpDetail(item.id)"
                  >
                    <p class="slText">...</p>
                  </td>
                  <td v-if="i == 0" :rowspan="item.orderDetails.length">
                    <p class="text-center">{{ item.payableAmount }}</p>
                  </td>
                  <td v-if="i == 0" :rowspan="item.orderDetails.length">
                    <p class="text-center">
                      {{
                        item.status === 1
                          ? "待支付"
                          : item.status === 2
                          ? "待发货"
                          : item.status === 3
                          ? "待收货"
                          : item.status === 4
                          ? "已完成"
                          : "已取消"
                      }}
                    </p>
                  </td>
                  <td  v-if="i == 0" :rowspan="item.orderDetails.length">
                    <p
                      class="text-center textBtn"
                      type="text"
                      size="small"
                      @click="jumpDetail(item.id)"
                    >
                      订单详情
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
        </table>
     </el-row>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { BillOrderList} from "api/order.js";
import { GetMemberStore } from "api/member.js";
import Qs from "qs";
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "ReportBox",
  data() {
    return {
      TimeValue:'',
      OrderId:'',//传给后台的搜索值id
      pagesize: 10,
      currentPage: 1,//当前页
      startTime: "",
      endTime: "",
      total: 0,
      size: 10, //每页数量
      //导航栏信息
      tableData: [],
      Shoplist:[],
      ShopVal:'',//传给后台的店铺值id
      NowMemberStoreId:'',
      // isIndeterminate: true,
      checkAll: false,
      checkedIds: [], // 初始选中的值
      checkedCount:0,
    };
  },
  methods: {
    async DownAttachFun(){
       if(this.checkedIds.length==0){
        // console.log(this.checkedIds)
          this.$message({
            message: "请勾选下载发票订单",
            type: "warning",
          });
       }else{
         console.log(this.checkedIds)
          for (const items of this.checkedIds) {  
            await this.DownAttach(items.attachId);
            await new Promise(resolve => setTimeout(resolve, 1000)); // 等待1秒
          }  
       }
    },

    async  DownAttach(PDFhref){
      // console.log('要下载了',PDFhref)
      let urlList=PDFhref.split(',')
      for (const file of urlList) {  
        await  this.downloadFile(file); // 等待每个文件下载完成  
        await new Promise(resolve => setTimeout(resolve, 1000)); // 等待1秒
      }  
        
    },
    async  downloadFile(fileUrl) {  
      // console.log(fileUrl)
      const a = document.createElement('a');  
      a.href = fileUrl;  
      a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);  
      a.style.display = 'none';  
      document.body.appendChild(a);  
      a.click();  
      document.body.removeChild(a);  
    } ,

    // 全选
    handleCheckAllChange(val) {
      let checkedIds = [];
      this.tableData.forEach((item) => {
        checkedIds.push(item);
        this.$set(item, 'flag', val ? 'true' : 'false'); 
        // item.flag=val ? 'true' : 'false'
      });
      this.checkedIds = val ? checkedIds : [];
      // console.log('全选后勾选数据',this.checkedIds)
    },
    // 单选
    handleCheckedChange(item){
     
      this.$set(item, 'flag', item.flag? 'true' : 'false');  
      // this.$forceUpdate();
      let checkedIds = [];
      this.tableData.forEach((items) => {
        // console.log(items.flag=='true')
        // console.log('现在',index+','+items.flag)
        if (items.flag == 'true') {
          checkedIds.push(items);
        }
      });
      this.checkedIds = checkedIds;
      console.log('单选完后勾选数据',this.checkedIds)
      let checkedCount = this.checkedIds.length;
      this.checkAll = checkedCount === this.tableData.length;
    },
 // 商品详情
 jumpGoodsDetail(item) {
      // alert(item.goodsType)
      // if(item.goodsType===4){
      //   console.log(item)
      //   // 跳a+b
      //    this.$router.push({
      //     name: "ComboDetail",
      //     query: { productId: item.goodsId, promotionId: item.promotionId },
      //   });
      // } else
      if (item.goodsType === 6) {
        // 跳套餐详情页
        this.$router.push({
          name: "SetMealDetail",
          query: { promotionId: item.goodsId },
        });
      } else {
        this.$router.push({
          name: "ProductDetail",
          query: {
            id: item.goodsId,
            isRecentExpiration: item.isRecentExpiration,
          },
        });
      }
    },
    jumpDetail(orderId) {
      // 订单详情
      
      this.$router.push({ path: "/OrderDetail", query: { orderId: orderId } });
    },
    // 店铺订单列表搜索
    ShopSearch(){
      // 调取接口 
      this.GetBillOrderPageFun();
    },
    handleSizeChange(val) {
      this.pagesize=val;
      let datas='';
      // 传每页显示数据
       datas={
          current: this.currentPage,
          size:  this.pagesize,
      };
      console.log(datas)
      // 调取接口 
      this.GetBillOrderPageFun();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
       this.GetBillOrderPageFun();
    },
   
    GetBillOrderPageFun(){
       // 显示loading
       this.$loading({ fullscreen: true, background: "#ffffff45" });
      let datas = "";
      datas = {
          storeId:this.ShopVal,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime: this.endTime,
        };
       //获取订单列表
       BillOrderList(datas).then(data=>{
         
        if (data.data.code === 400) {
          this.$message.error(data.data.msg);
        }

        let recordsArr=data.data.data.records
        this.tableData=recordsArr.map(item => ({ ...item })); // 浅拷贝数据以避免直接修改原始数据
        console.log('订单列表1111111111', this.tableData)
         //给每一条数据都加一个状态值
         this.tableData.forEach((item) => {
            this.$set(item, 'flag', 'false'); 
            // console.log('最初',index+','+item.flag)
          });

          this.checkedIds=[];
          let checkedCount = this.checkedIds.length;
          this.checkAll = checkedCount === this.tableData.length;

         // 初始获取所有数据数量
        this.total = data.data.data.total;
        // 关闭loading
        this.$loading().close();
      })
    },
  },
  created() {
    this.NowMemberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
     //  获取门店列表接口
     GetMemberStore().then(res=>{
        console.log('门店列表',res.data.data)
        this.Shoplist=res.data.data
        // 当前门店id
        this.Shoplist.forEach((item,index)=>{
          if(item.id==this.NowMemberStoreId){
            this.ShopVal=res.data.data[index].id
            this.GetBillOrderPageFun();

          }
        })
      })
  },
  components: {
    TitleHeader,
  },
};
</script>
<style>
.el-pager li.active {
  color: #ff3333;
}
.el-pager li:hover {
  color: #ff3333;
}
/* 表格样式 */
.ReportBox .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #fff !important;
}
.ReportBox .el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.ReportBox .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: none;
}
/* th样式 */
.ReportBox .el-table th {
  background-color: #eee;
  text-align: center;
}
/* td样式 */
.ReportBox .el-table thead .cell {
  text-align: center;
    font-size: 13px;
    color:#666;
}
.ReportBox .el-table tbody .cell {
  text-align: center;
    font-size:12px;
    color:#333;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ff3333;
    color: #fff;
  }
  /* 上一页，下一页样式 */
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    background: #fff;
    color: #666;
    box-sizing: border-box;
  }

  /* 每个页码的样式 */
  .el-pagination.is-background .el-pager li {
    background: #fff;
    color: #666;
    box-sizing: border-box;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #ff3333;
    font-size: 14px;
  }
</style>
<style lang="less" scoped>
@import "~style/index.less";
  .progressBar {
    height: 20px;
  }
.header {
  padding-left:10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  position: relative;
    .downBtn {
      background: #ff3333;
      color: #fff;
      padding: 10px 10px;
      border: none;
      position: absolute;
      right: 10px;
      top: 12px;
      cursor: pointer;
      outline: none;
    }
}
.ulBox {
    margin-top: 20px;
    background: #f6f6f6;
    height: 38px;
    line-height: 30px;
    margin-bottom: 20px;
    li {
      float: left;
      text-align: center;
      color: #666666;
      width: 128px;
      line-height: 38px;
      font-size: 12px;
    }
    li:nth-child(2) {
      width: 180px;
    }
    li:nth-child(3) {
      width: 124px;
    }
    li:nth-child(4) {
      width: 140px;
    }
    li:nth-child(5) {
      width: 180px;
    }
    li:first-child {
      width:30px;
    }
    li:last-child {
      width: calc(100% - 790px);
    }
  }
  .Detailitem {
    margin-bottom: 20px;
    background: #fff;
    font-size: 12px;
    color: #999;
    .headers {
      .checkItemBox{
        float: left;
        width: 30px;
        display: flex;
        justify-content: space-evenly;
        padding-right: 20px;
      }
      font-size: 12px;
      padding:10px 0;
      background: #eee;
    }
    table.main {
      width: 100%;
      border: 1px solid #ccc;
      border-collapse: collapse;
      .tableBox {
        padding: 10px 10px 10px 0;
        text-align: left;
        .slText {
          font-size: 26px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover {
            color: #ff3333;
          }
        }
      }
      thead th {
        text-align: center;
        background-color: #fafafa;
      }
      th,
      td {
        border: 1px solid #e9eaec;
        line-height: 30px;
        text-align: center;
      }
      .imgBox {
        float: left;
        width: 72px;
        height: 72px;
        margin: 0 14px;
        border: 1px solid #f6f6f6;
        img {
          display: inline-block;
          width: 70px;
          height: 70px;
          overflow: hidden;
        }
      }
      .desBox {
        float: left;
        padding: 14px 0;
        width: calc(100% - 120px);
        .desTit {
          width: 100%;
          height: 20px;
          cursor: pointer;
          line-height: 20px;
          span:nth-child(1) {
            max-width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            font-weight: bold;
            color: #333;
            float: left;
          }
          span:nth-child(2) {
            font-size: 13px;
            color: #666;
            float: right;
          }
        }
        .desMsg {
          width: 100%;
          height: 20px;
          line-height: 20px;
          font-size: 13px;
          color: #999;
        }
      }
    }
  }
.wraper {
  margin-top: 30px;
  .el-table thead {
    background: red;
  }
}
.el-pagination {
  padding-top: 40px;
  text-align: center;
}
</style>
